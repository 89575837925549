import React, { Component } from "react";

class Modal extends Component {
  state = {};
  render() {
    return (
      <div className="modal_wrapper">
        <div className="modal" key={this.props.modalData.modalId}>
          <div className="question">{this.props.modalData.question}</div>
          <div className="buttons">
            <div
              className={"yes " + this.props.modalData.yesClass}
              onClick={this.props.yesFunction}>
              {this.props.modalData.yesLabel}
            </div>
            <div
              className={"no " + this.props.modalData.noClass}
              onClick={this.props.noFunction}>
              {this.props.modalData.noLabel}
            </div>
          </div>
        </div>
        <div
          className="overlay"
          onClick={this.props.noFunction}>
        </div>
      </div>
    );
  }
}

export default Modal;
