import React, { Component } from 'react';
import { displaySliderPrice, displayDescription, displayInfoButton } from "../Globals";

class Slider extends Component {
  setLabel = () => {
    let label = this.props.data.slider_presets.value;
    return label;
  }
  slideElement = val => {
    let el = this.props.data;
    el.slider_presets.value = val.target.value;
    this.setLabel();
    this.props.handleElementChange(el, this.props.path);
    return true;
  }

  render() {
    const slider = this.props.data;
    return (
      <div className="slider_field">
        <div className="title_wrapper">
          <h2>{slider.name}</h2><span className="label">: ({this.setLabel()})</span>{displayInfoButton(slider, this.props.class)}<span className="mobile_line"></span>{displaySliderPrice(slider)}
        </div>
        {displayDescription(slider, this.props.class)}
        <input
          type="range"
          min={slider.slider_presets.min}
          value={slider.slider_presets.value}
          max={slider.slider_presets.max}
          className="slider"
          onChange={this.slideElement}
        ></input>

      </div>
    );
  }
}

export default Slider;