import React, { Component } from "react";

class TODOS extends Component {
  state = {};
  render() {
    const todos = [
      "talk to Ervin about organization of Customer/Quote relationships"
    ];
    return (
      <div className="todos">
        {todos.map((todo, i) => {
          return <div key={i}>{i + 1 + ". " + todo}</div>;
        })}
      </div>
    );
  }
}

export default TODOS;
