import React, { Component } from 'react';
import { classify, calculatePageTotal, formatMoney, additionalPageCosts, calculateTotal, additionalCosts } from '../Globals';
class NavBar extends Component {

  isCurrent(i) {
    if (i === this.props.nav_marker) {
      return " current";
    } else if (i <= this.props.nav_marker) {
      return " prev";
    } else {
      return "";
    }
  }
  render() {
    const progress = (100 / ((this.props.data.pages).length)) * this.props.nav_marker;
    return (
      <div className="navbar">
        <div className={"progress active" + this.props.nav_marker}>
          <div className="markers">
            {this.props.data.pages.map((page, i) => (

              <div
                key={page.name}
                className={"marker " + classify(page.name) + this.isCurrent(i)}
                onClick={() => { this.props.handleNavMarker(i); }}
              >
                <i className={page.icon}></i>
                <div className="marker_cost">{formatMoney(calculatePageTotal(page.elements, "flat"))}
                  {additionalPageCosts(page.elements)}</div>
              </div>
            ))}
            <div
              key="Review"
              className={"marker review" + this.isCurrent((this.props.data.pages).length)}
              onClick={() => { this.props.handleNavMarker((this.props.data.pages).length); }}
            >
              <div className="marker_cost">{formatMoney(calculateTotal(this.props.data.pages, "flat"))}{additionalCosts(this.props.data.pages)}</div>
              <i className="fa fa-usd"></i>
            </div>
            <div className={"progress_bar active" + this.props.nav_marker}>
              <div className="current_step" style={{ width: progress + "%" }}></div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default NavBar;