import React, { Component } from 'react';
import { displayCheckboxPrice, displaySelectPrice, displaySliderPrice, displayDefaultPrice } from './Globals';
class BreakdownElement extends Component {
  displayPrice = element => {
    const type = element.type;
    let price;
    let toggle = 1;
    if (type === "checkbox" || type === "mandatory") {
      if (element.cost === 0 && element.dependencies[0] !== false && element.toggle === 1) {
        toggle = 0;
      }
      price = displayCheckboxPrice(element);
    } else if (type === "slider") {
      price = displaySliderPrice(element);
    } else if (type === "select") {
      price = displaySelectPrice(element);
    } else {
      price = displayDefaultPrice(element);
    }
    if (price === "" && toggle === 1) {
      price = <span className="element_price">0</span>
    }
    return price;
  }
  render() {
    return (
      <div className="elements_breakdown">
        <div className="element_breakdown">
          <h3>{this.props.element.name}</h3>
          {this.displayPrice(this.props.element)}
        </div>
        {
          this.props.element.dependencies.map((dependency, i) => {
            if (dependency !== false) {
              let toggle = this.props.element.toggle;

              if (toggle) {
                return (
                  <BreakdownElement
                    key={this.props.parent + "dependency" + i}
                    element={dependency}
                    parent={this.props.parent + "dependency" + i}
                    dependency={1}
                  />)
              } else {
                return "";
              }
            } else {
              return "";
            }
          })}

      </div>
    );
  }
}

export default BreakdownElement;