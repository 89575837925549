import React, { Component } from 'react';
import Element from './Elements/Element';
import PageTotal from './PageTotal';

class Page extends Component {
  state = {}
  elementAtLevel = (element, path_arr, changed_element) => {

    const index = parseInt(path_arr[0]);
    // console.log(index);
    // console.log(element);
    if (path_arr.length > 1) {
      path_arr.shift();
      return this.elementAtLevel(element.dependencies[index], path_arr, changed_element);
    } else {
      element = changed_element;
      return element;
    }
  }
  handleElementChange = (element, path) => {
    // console.log(i);
    let path_arr = [];
    // console.log(path_arr.length);
    if (typeof (path) !== 'number') {
      path_arr = path.split("-");
    }
    // const path_arr = path.split("-");
    // console.log(path_arr.length);
    let elements = this.props.page.elements;
    if (path_arr.length === 0) {
      elements[path] = element;
    } else {
      // console.log(path_arr);
      const parent_el = elements[parseInt(path_arr[0])];
      path_arr.shift();
      this.elementAtLevel(parent_el.dependencies[parseInt(path_arr[0])], path_arr, element);
      // console.log(dependencies);
    }

    this.props.handlePageChange(elements, this.props.page_num);
    return true;
  }
  render() {
    return (
      <div className={"page page_" + this.props.page_num + " " + this.props.active}>
        <div className="page_title"><h1>{this.props.page.name}</h1></div>
        {this.props.page.elements.map((element, i) => (
          <Element
            class=""
            key={this.props.page_num + "" + i}
            parent=""
            dependency_key={this.props.page_num + "" + i}
            element={element}
            index={i}
            path={i}
            handleElementChange={this.handleElementChange}
          />
        ))}
        <PageTotal
          page_title={"Page Total"}
          page={this.props.page}
        />
      </div>
    );
  }
}

export default Page;