import React, { Component } from "react";
import Save from "./Save";
import ViewSaved from "./ViewSaved";
class Menu extends Component {
  state = {
    view: false,
    menu_state: ""
  };
  handleTriggerReset = () => {
    const view = false;
    this.setState({ view });
  };
  triggerView = () => {
    const view = true;
    this.setState({ view });
  };
  removeNav = () => {
    const menu_state = "";
    this.setState({ menu_state });
  };
  toggleNav = ham => {
    if (this.state.menu_state === "") {
      const menu_state = " active";
      this.setState({ menu_state });
    } else {
      const menu_state = "";
      this.setState({ menu_state });
    }
  };
  render() {
    return (
      <div className={"menu" + this.state.menu_state}>
        <div className="nav">
          <div className="hamburger-wrapper" onClick={this.toggleNav}>
            <div className="hamburger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <Save
            saveData={this.props.curr}
            // passSave={name => this.props.passSave(name)}
            triggerView={this.triggerView}
          />
          <ViewSaved
            saveData={this.props.curr}
            handleStatePass={state => this.props.handleStatePass(state)}
            handleTriggerReset={this.handleTriggerReset}
            triggered={this.state.view}
            removeNav={this.removeNav}
          />
        </div>
      </div>
    );
  }
}

export default Menu;
