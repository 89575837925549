import React, { Component } from "react";
import Menu from "./Menu";
import Autosizeinput from "../Elements/Autosizeinput";
import { calculateTotal, formatMoney, additionalCosts } from "../Globals";
class TopBar extends Component {
  handleChange = e => {
    this.props.clientChange(e.target.value);
  };

  render() {
    return (
      <div className="topbar">
        <Menu
          curr={this.props.curr}
          handleStatePass={state => this.props.handleStatePass(state)}
          // passSave={(name) => this.props.passSave(name)}
        />
        <div className="project_specs">
          <div className="project_name">{this.props.project}</div>
          {/* <input className="client_name" type="text" value={this.props.client} name="client" onChange={this.handleChange}></input> */}
          <Autosizeinput
            name="client"
            value={this.props.client}
            onChange={this.handleChange}
            extraWidth="20px"
          />
        </div>
        <span className="top_total">
          {formatMoney(calculateTotal(this.props.pages, "flat")) +
            additionalCosts(this.props.pages)}
        </span>
      </div>
    );
  }
}

export default TopBar;
