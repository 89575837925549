import React, { Component } from 'react';
import PageTotal from './PageTotal';
import { classify, formatMoney, calculateTotal } from './Globals';
import BreakdownElement from './BreakdownElement';

class Breakdown extends Component {
  render() {
    const billing_types = ["flat", "yearly", "monthly"];
    const billing_suffix = ["", "/yr", "/mo"];
    return (
      <div className={"page page_" + this.props.page_num + " " + this.props.active}>
        {this.props.pages.map((page, i) => (
          <div key={classify(page.name + i)} className="page_breakdown">
            <PageTotal
              page_title={page.name}
              page={page}
            />
            {page.elements.map((element, j) => (
              <BreakdownElement
                key={classify("page" + i + " element" + j)}
                element={element}
                parent={classify("page" + i + " element" + j)}
                dependency={0}
              />
            ))}
          </div>
        ))}
        <div className="page_total">
          <span className="h2">Grand Total</span>
          <div className="billing_types">
            {billing_types.map((billing_type, i) => {
              const type_total = calculateTotal(this.props.pages, billing_type);
              if (type_total > 0) {
                return <div key={billing_type} className="h2 billing_type">{formatMoney(type_total) + billing_suffix[i]}</div>
              } else {
                return "";
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Breakdown;