import React, { Component } from 'react';
import Autosizeinput from './Autosizeinput';
import { formatMoney, displayDescription, displayInfoButton } from '../Globals';

class TextArea extends Component {
  customElement = val => {
    let el = this.props.data;
    el.value = val.target.value;
    this.props.handleElementChange(el, this.props.path);
    const offset = val.target.offsetHeight - val.target.clientHeight;
    if ((val.target.style.height !== val.target.scrollHeight) && !val.target.classList.contains('resized')) {
      val.target.style.height = 'auto';
      val.target.style.height = (val.target.scrollHeight + offset) + "px";
    }
    return true;
  }
  changeEstimate = val => {
    let el = this.props.data;
    el.cost = parseFloat(val.target.value.replace(/,/gi, ""));
    el.cost = el.cost || 0;
    this.props.handleElementChange(el, this.props.path);
    return true;
  }
  handleResize = () => {
    const textarea = document.querySelector('.custom_field textarea');
    let down_height = 0;
    let up_height = 0;
    textarea.addEventListener('mousedown', (event) => {
      down_height = event.target.offsetHeight;
      // console.log(down_height);
    }, true);
    textarea.addEventListener('mouseup', (event) => {
      up_height = event.target.offsetHeight;
      if (up_height !== down_height) {
        event.target.classList.add('resized');
        down_height = up_height;
      }
    }, true);
  }
  handleSetHeight = () => {

  }
  componentDidMount = () => {
    this.handleResize();
  }
  componentDidUpdate = () => {
    this.handleResize();


    const textareas = document.querySelectorAll('.textarea_wrapper > textarea');
    textareas.forEach((el) => {
      const offset = el.offsetHeight - el.clientHeight;
      if ((el.style.height !== el.scrollHeight) && !el.classList.contains('resized')) {
        el.style.height = 'auto';
        el.style.height = (el.scrollHeight + offset) + "px";
      }
    });
  }


  render() {
    const custom = this.props.data;
    return (
      <div className="custom_field">
        <div className="title_wrapper">
          <h2>{custom.name}</h2>{displayInfoButton(custom, this.props.class)}

          <div className="estimate element_price">
            {/* <input className="estimate_cost" type="text" value={formatMoney(custom.cost)} name="client" onChange={this.changeEstimate}></input> */}
            <Autosizeinput
              name="estimate_cost"
              value={formatMoney(custom.cost)}
              onChange={this.changeEstimate}
              extraWidth={10}
            />
          </div>
        </div>
        {displayDescription(custom, this.props.class)}
        <div className="textarea_wrapper">
          <textarea value={custom.value} onChange={this.customElement} />
        </div>
      </div>
    );
  }
}

export default TextArea;
