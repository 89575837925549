import React, { Component } from "react";
import Modal from "../Modules/Modal";

import Axios from "axios";
import qs from "qs";

class ViewSaved extends Component {
  state = {
    view_init: false,
    saved_list: [],
    modal: ""
  };

  removeModal = () => {
    const modal = "";
    this.setState({ modal });
    // console.log(this.state.modal);
  };
  promptRemove = del => {
    const id = del.target.dataset.id;
    const modalData = {
      question: "Are you sure you want to delete this saved estimate?",
      yesLabel: "Confirm",
      yesClass: "btn btn-primary",
      noLabel: "Cancel",
      noClass: "btn btn-secondary",
      modalId: "modal_" + id
    };

    const modal = (
      <Modal
        key={modalData.modalId}
        modalData={modalData}
        yesFunction={() => this.handleRemove(id)}
        noFunction={this.removeModal}
      />
    );

    this.setState({ modal });
  };
  promptOverwrite = overwrite => {
    const id = overwrite.target.dataset.id;
    const modalData = {
      question:
        "Are you sure you want to overwrite this saved estimate?  The overwritten estimate will be lost.",
      yesLabel: "Confirm",
      yesClass: "btn btn-primary",
      noLabel: "Cancel",
      noClass: "btn btn-secondary",
      modalId: "modal_" + id
    };

    const modal = (
      <Modal
        key={modalData.modalId}
        modalData={modalData}
        yesFunction={() => this.handleOverwrite(id)}
        noFunction={this.removeModal}
      />
    );
    this.setState({ modal });
  }
  promptLoad = load => {
    const id = load.target.dataset.id;
    const modalData = {
      question:
        "Are you sure you want to Load this saved estimate?  You will lose current your progress.",
      yesLabel: "Confirm",
      yesClass: "btn btn-primary",
      noLabel: "Cancel",
      noClass: "btn btn-secondary",
      modalId: "modal_" + id
    };
    const modal = (
      <Modal
        key={modalData.modalId}
        modalData={modalData}
        yesFunction={() => this.handleLoad(id)}
        noFunction={this.removeModal}
      />
    );
    this.setState({ modal });
  };
  handleOverwrite = id => {
    Axios.post(
      "/assets/php/overwrite.php",
      qs.stringify({
        id: id,
        state: this.props.saveData
      })
    ).then(response => {
      if (response.data === "success") {
        // console.log('success')
        this.getSavedList(true);
        this.props.removeNav();
        this.removeModal();
      } else {
        // console.log(response.data);
      }
    });
  }

  handleRemove = id => {
    Axios.post(
      "/assets/php/delete.php",
      qs.stringify({
        id: id
      })
    ).then(response => {
      if (response.data === "success") {
        // console.log('success')
        this.getSavedList(true);
        this.removeModal();
      } else {
        // console.log(response.data);
      }
    });
  };
  handleLoad = id => {
    Axios.post(
      "/assets/php/load.php",
      qs.stringify({
        id: id
      })
    )
      .then(response => {
        const new_state = response.data;
        // console.log(new_state);
        this.props.handleStatePass(new_state);
        this.getSavedList(true);
        this.props.removeNav();
        this.removeModal();
      })
      .catch();
  };
  getSavedList = refresh => {
    if (this.state.view_init === false || refresh) {
      Axios.post("/assets/php/display.php")
        .then(response => {
          // console.log(response);
          const saved_list = response.data;
          this.setState({ saved_list });
          const view_init = true;
          this.setState({ view_init });
        })
        .catch();
    }
  };
  componentDidUpdate = () => {
    if (this.props.triggered) {
      this.getSavedList(true);
      this.props.handleTriggerReset();
    }
  };
  render() {
    this.getSavedList(false);
    // console.log(this.state.saved_list);
    return (
      <>
        <div className="saved_items">
          {/* <div className="saved_item">
            <div className="name">hardcode test</div>
            <div className="date">1234-12-34</div>
            <div className="btn-wrapper">
              <div
                className="btn btn-primary"
                data-id={54}
                onClick={this.promptLoad}
              >
                Load
              </div>
            </div>
            <div className="btn-wrapper">
              <div
                className="btn btn-secondary"
                data-id={54}
                onClick={this.promptRemove}
              >
                Delete
              </div>
            </div>
          </div> */}
          {/**/
            this.state.saved_list.map(item => {
              return (
                <div className="saved_item">
                  <div className="name">{item["name"]}</div>
                  <div className="date">{item["date"]}</div>
                  <div className="btn-wrapper">
                    <div
                      className="btn btn-primary"
                      data-id={item["id"]}
                      onClick={this.promptOverwrite}>
                      Update
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <div
                      className="btn btn-tertiary"
                      data-id={item["id"]}
                      onClick={this.promptLoad}>
                      Load
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <div
                      className="btn btn-secondary"
                      data-id={item["id"]}
                      onClick={this.promptRemove}
                    >
                      Delete
                  </div>
                  </div>
                </div>
              );
            })
            /**/
          }
        </div>
        {this.state.modal}
      </>
    );
  }
}

export default ViewSaved;
