import React, { Component } from 'react';
import { displayCheckboxPrice, displayDescription, displayInfoButton } from "../Globals";
class Checkbox extends Component {
  state = {}

  toggleElement = () => {
    let el = this.props.data;
    if (el.toggle === 1) {
      el.toggle = 0;
    } else {
      el.toggle = 1;
    }
    this.props.handleElementChange(el, this.props.path);
    return true;
  }

  render() {
    const checkbox = this.props.data;
    return (
      <div className="checkbox_field">
        <div className="title_wrapper">
          <h2 className="label">{checkbox.name}</h2>{displayInfoButton(checkbox, this.props.class)}
          <div className="checkbox_wrapper"><input type="checkbox" className={"mandatory_" + this.props.mandatory} onClick={this.toggleElement} defaultChecked={checkbox.toggle} data-checked={checkbox.toggle} /></div>
          <span className="mobile_line"></span>{displayCheckboxPrice(checkbox)}
        </div>
        {displayDescription(checkbox, this.props.class)}
      </div>
    );
  }
}

export default Checkbox;