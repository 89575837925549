import React, { Component } from "react";
import Page from "./components/Page";
import Breakdown from "./components/Breakdown";
import TopBar from "./components/Navigation/TopBar";
import NavBar from "./components/Navigation/NavBar";
import TODOS from "./TODOS";

import website from "./data/website.json";

class Calculator extends Component {
  state = {
    project: "Project A",
    client: "Client A",
    nav_marker: 0,
    app: website
  };
  // componentDidUpdate() {
  //   console.log(this.state);
  // }
  resetAppDataTypes = app => {
    app.pages.forEach(page => {
      // console.log('test::::');
      // console.log(page.elements);
      page.elements.forEach(element => {
        element = this.resetElement(element);
        // console.log(element);
      });
    });
    return app;
  };
  resetPage = page => {
    return page;
  };
  resetElement = element => {
    const type = element.type;
    switch (type) {
      case "select":
        element = this.resetSelect(element);
        break;
      case "slider":
        element = this.resetSlider(element);
        break;
      case "checkbox":
        element = this.resetCheckbox(element);
        break;
      case "mandatory":
        element = this.resetCheckbox(element);
        break;
      case "textarea":
        element = this.resetTextarea(element);
        break;
      default:
        console.log("no function for " + type);
        break;
    }
    return element;
  };
  resetDependencies = dependencies => {
    if (dependencies[0] === "false") {
      return [false];
    } else {
      let dep_arr = [];
      dependencies.forEach((dependency, i) => {
        dep_arr[i] = this.resetElement(dependency);
      });
      return dep_arr;
    }
  };
  resetSelect = el => {
    el.select_presets.value_labels.forEach(value_label => {
      value_label.cost = parseInt(value_label.cost);
    });
    el.dependencies = this.resetDependencies(el.dependencies);
    return el;
  };
  resetSlider = el => {
    el.cost = parseInt(el.cost);

    el.slider_presets.min = parseInt(el.slider_presets.min);
    el.slider_presets.max = parseInt(el.slider_presets.max);
    el.slider_presets.value = parseInt(el.slider_presets.value);
    el.slider_presets.step = parseInt(el.slider_presets.step);
    el.dependencies = this.resetDependencies(el.dependencies);
    return el;
  };
  resetCheckbox = el => {
    el.toggle = parseInt(el.toggle);
    el.cost = parseInt(el.cost);
    el.dependencies = this.resetDependencies(el.dependencies);
    return el;
  };
  resetTextarea = el => {
    el.cost = parseInt(el.cost);
    el.dependencies = this.resetDependencies(el.dependencies);
    return el;
  };

  handleStatePass = e => {
    const new_state = JSON.parse(e);

    const project = new_state.project;
    const client = new_state.client;
    const nav_marker = parseInt(new_state.nav_marker);
    let app = new_state.app;

    app = this.resetAppDataTypes(app);

    // console.log(this.state.app);
    // console.log(app);

    this.setState({ project });
    this.setState({ client });
    this.setState({ nav_marker });
    this.setState({ app });
  };
  handleMainNavMarker = a => {
    const nav_marker = a;
    this.setState({ nav_marker });
  };

  handlePageChange = (elements, i) => {
    let app = this.state.app;
    // console.log(elements);
    app.pages[i].elements = elements;
    this.setState({ app });
    //console.log(app);
  };
  isCurrent(i) {
    if (i < this.state.nav_marker) {
      return "prev_page";
    } else if (i > this.state.nav_marker) {
      return "next_page";
    } else {
      return "curr_page";
    }
  }
  handleClientChange = client => {
    this.setState({ client });
  };
  render() {
    // console.log(this.state.app);
    return (
      <>
        {/* <TODOS /> */}
        <div className="calculator">
          <TopBar
            curr={this.state}
            project={this.state.project}
            client={this.state.client}
            clientChange={this.handleClientChange}
            pages={this.state.app.pages}
            handleStatePass={this.handleStatePass}
          // passSave={this.handleSave}
          />
          <NavBar
            data={this.state.app}
            service={this.state.service}
            nav_marker={this.state.nav_marker}
            handleNavMarker={this.handleMainNavMarker}
          />
          <div className="pages">
            {this.state.app.pages.map((page, i) => (
              <Page
                key={"page" + i}
                page_num={i}
                page={page}
                active={this.isCurrent(i)}
                handlePageChange={this.handlePageChange}
                handleNavMarker={this.handleMainNavMarker}
              />
            ))}
            <Breakdown
              key={"page" + this.state.app.pages.length}
              page_num={this.state.app.pages.length}
              pages={this.state.app.pages}
              active={this.isCurrent(this.state.app.pages.length)}
              handlePageChange={this.handlePageChange}
              handleNavMarker={this.handleMainNavMarker}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Calculator;
