import React, { Component } from 'react';
import { calculatePageTotal, formatMoney } from './Globals';

class PageTotal extends Component {
  displayZero = (zero_checker) => {
    if (zero_checker === 0) {
      return <div key="zero_checker" className="h2 billing_type">$ {0}</div>
    } else {
      return "";
    }
  }
  render() {
    let zero_checker = 0;
    const billing_types = ["flat", "yearly", "monthly"];
    const billing_suffix = ["", "/yr", "/mo"];
    return (
      <div className="page_total">
        <span className="h2">{this.props.page_title}</span>
        <div className="billing_types">

          {billing_types.map((billing_type, i) => {
            const type_total = calculatePageTotal(this.props.page.elements, billing_type);
            zero_checker += type_total;
            if (type_total > 0) {
              return <div key={billing_type} className="h2 billing_type">{formatMoney(type_total) + billing_suffix[i]}</div>
            } else {
              return "";
            }
          })}
          {this.displayZero(zero_checker)}
        </div>

      </div>
    );
  }
}

export default PageTotal;