import React, { Component } from "react";
import {
  classify,
  displaySelectPrice,
  displayDescription,
  displayInfoButton
} from "../Globals";

class Select extends Component {
  selectElement = val => {
    let el = this.props.data;
    el.select_presets.value = val.target.value;
    this.props.handleElementChange(el, this.props.path);
    return true;
  };

  render() {
    const select = this.props.data;
    return (
      <div className="select_field">
        <div className="title_wrapper">
          <h2>{select.name}</h2>
          {displayInfoButton(select, this.props.class)}
          <div className="select_wrapper">
            <select
              className="select"
              onChange={this.selectElement}
              value={select.select_presets.value}
            >
              {select.select_presets.value_labels.map((option, i) => (
                <option
                  key={classify(this.props.data.name) + i}
                  value={option.name}
                >
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          {displaySelectPrice(select)}
        </div>
        {displayDescription(select, this.props.class)}
      </div>
    );
  }
}

export default Select;
