import React from "react";

export const classify = arg => {
  const classed = arg.replace(/[^A-Z0-9]+/gi, "_").toLowerCase();
  return classed;
};
export const formatMoney = a => {
  return a.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
export const calculatePageTotal = (elements, billing_type) => {
  let tally = 0;
  elements.forEach(element => {
    let enabled = false;
    const billing_cycle = element.billing_cycle;
    const type = element.type;

    if (type === "select") {
      const value = element.select_presets.value;
      element.select_presets.value_labels.forEach(value_label => {
        if (value_label.name === value) {
          if (billing_type === billing_cycle) {
            tally += value_label.cost;
          }
        }
        enabled = true;
      });
    } else if (type === "checkbox") {
      const cost = element.cost;
      const toggle = element.toggle;
      if (toggle === 1) {
        enabled = true;
      }
      if (billing_type === billing_cycle) {
        tally += cost * toggle;
      }
    } else if (type === "mandatory") {
      const cost = element.cost;
      const toggle = element.toggle;
      if (toggle === 1) {
        enabled = true;
      }
      if (billing_type === billing_cycle) {
        tally += cost * toggle;
      }
    } else if (type === "slider") {
      const cost = element.cost;
      const slider_value = element.slider_presets.value;
      enabled = true;
      if (billing_type === billing_cycle) {
        tally += cost * slider_value;
      }
    } else {
      const cost = element.cost;
      enabled = true;
      if (billing_type === billing_cycle) {
        tally += cost;
      }
    }
    if (element.dependencies[0] !== false && enabled) {
      tally += parseInt(calculatePageTotal(element.dependencies, billing_type));
    }
  });
  tally = tally || 0;
  return tally;
};

export const calculateTotal = (pages, billing_type) => {
  let tally = 0;
  pages.forEach(page => {
    const elements = page.elements;
    tally += parseInt(calculatePageTotal(elements, billing_type));
  });
  tally = tally || 0;
  return tally;
};
export const additionalPageCosts = elements => {
  const monthly = calculatePageTotal(elements, "monthly");
  const yearly = calculatePageTotal(elements, "yearly");
  if (monthly > 0 || yearly > 0) {
    return "+";
  } else {
    return "";
  }
};
export const additionalCosts = pages => {
  const monthly = calculateTotal(pages, "monthly");
  const yearly = calculateTotal(pages, "yearly");
  if (monthly > 0 || yearly > 0) {
    return "+";
  } else {
    return "";
  }
};
export const displaySelectPrice = select => {
  const select_presets = select.select_presets;
  let element_price = "";
  select_presets.value_labels.forEach(value_label => {
    if (value_label.name === select_presets.value) {
      element_price = (
        <span className="element_price">{formatMoney(value_label.cost)}</span>
      );
    }
  });
  return element_price;
};
export const displaySliderPrice = slider => {
  return (
    <span className="element_price">
      {formatMoney(slider.cost * slider.slider_presets.value)}
    </span>
  );
};
export const displayCheckboxPrice = checkbox => {
  let billing_term = "";
  if (checkbox.billing_cycle === "monthly") {
    billing_term = "/mo";
    //billing_term += " ($ " + formatMoney(checkbox.cost * checkbox.toggle * 12) + "/yr)";
  } else if (checkbox.billing_cycle === "yearly") {
    billing_term = "/yr";
  }

  if (checkbox.cost > 0) {
    return (
      <span className="element_price">
        {formatMoney(checkbox.cost * checkbox.toggle)}
        {billing_term}
      </span>
    );
  } else {
    return "";
  }
};
export const displayDefaultPrice = custom => {
  return <span className="element_price">{formatMoney(custom.cost)}</span>;
};
export const displayDescription = (element, id) => {
  if (element.description !== "") {
    return (
      <div id={id} className={"element_description"}>
        {element.description}
      </div>
    );
  } else {
    return "";
  }
};
export const displayInfoButton = (element, id) => {
  if (element.description !== "") {
    return (
      <i
        className="info fa fa-info-circle"
        aria-hidden="true"
        data-coupled={id}
        onClick={e => {
          toggleDescription(id);
          wait_period(e);
        }}
      ></i>
    );
  } else {
    return "";
  }
};
export const toggleDescription = id => {
  //console.log(id);
  const el = document.getElementById(id);
  el.classList.toggle("active");
  if (el.classList.contains("active")) {
    el.style.paddingBottom = el.scrollHeight + "px";
    el.classList.toggle("wait");
  } else {
    el.style.paddingBottom = "0px";
  }
};
export const wait_period = e => {
  const info = e.target;
  info.classList.toggle("wait");
  setTimeout(function () {
    info.classList.toggle("wait");
  }, 751);
};
