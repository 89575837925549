import React, { Component } from "react";
import Autosizeinput from "../Elements/Autosizeinput";

import Axios from "axios";
import qs from "qs";

class Save extends Component {
  state = {
    name: "",
    isError: false
  };

  handleNameChange = input => {
    const name = input.target.value;
    const isError = false;
    this.setState({ isError });
    this.setState({ name });
  };
  validateSave = () => {
    if (this.state.name === null || this.state.name === "") {
      const isError = true;
      this.setState({ isError });
      return false;
    } else {
      const isError = false;
      this.setState({ isError });
      this.handleSave();
    }
  };
  handleSave = name => {
    // console.log(this.props.saveData);
    Axios.post(
      "/assets/php/save.php",
      qs.stringify({
        name: this.state.name,
        state: this.props.saveData
      })
    )
      .then(response => {
        //const data = response.config.data;
        // console.log(response);
        const name = "";
        this.setState({ name });
        document.querySelector(".saveName").value = "";
        this.props.triggerView();
      })
      .catch();
  };

  render() {
    return (
      <div className="saving" data-error={this.state.isError}>
        <Autosizeinput
          placeholder="Name your Save"
          name=""
          className="saveName"
          value={this.state.name}
          onChange={this.handleNameChange}
        />
        {/* <input className="saveName" onChange={this.handleNameChange} /> */}
        <div className="save-btn btn" data-error={this.state.isError} onClick={this.validateSave}>
          New Save
        </div>
      </div>
    );
  }
}

export default Save;
