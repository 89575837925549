import React, { Component } from 'react';
import Checkbox from './Checkbox';
import Slider from './Slider';
import Select from './Select';
import TextArea from './TextArea';

import { classify } from '../Globals';

class Element extends Component {
  state = {}
  elementType = () => {
    const type = this.props.element.type;
    let field;

    switch (type) {
      case "mandatory":
        field = <Checkbox mandatory={true} class={"el" + this.props.dependency_key} path={this.props.path} level={this.props.level} index={this.props.index} data={this.props.element} handleElementChange={(e, p) => this.props.handleElementChange(e, p)} />;
        break;
      case "checkbox":
        field = <Checkbox mandatory={false} class={"el" + this.props.dependency_key} path={this.props.path} level={this.props.level} index={this.props.index} data={this.props.element} handleElementChange={(e, p) => this.props.handleElementChange(e, p)} />;
        break;
      case "slider":
        field = <Slider class={"el" + this.props.dependency_key} path={this.props.path} level={this.props.level} index={this.props.index} data={this.props.element} handleElementChange={(e, p) => this.props.handleElementChange(e, p)} />;
        break;
      case "select":
        field = <Select class={"el" + this.props.dependency_key} path={this.props.path} level={this.props.level} index={this.props.index} data={this.props.element} handleElementChange={(e, p) => this.props.handleElementChange(e, p)} />;
        break;
      case "textarea":
        field = <TextArea class={"el" + this.props.dependency_key} path={this.props.path} level={this.props.level} index={this.props.index} data={this.props.element} handleElementChange={(e, p) => this.props.handleElementChange(e, p)} />;
        break;
      default:
        break;
    }
    return field;
  }


  render() {
    return (
      <div className={"field_wrapper " + this.props.class + " " + this.props.parent + classify(this.props.element.name)
      }>
        {this.elementType()}
        {
          this.props.element.dependencies.map((dependency, index) => {
            if (dependency !== false) {
              let dependency_class = "";
              if (this.props.element.dependencies.length > 0 && this.props.element.toggle === 0) {
                dependency_class = " disabled";
              }
              return <Element
                key={this.props.dependency_key + "-dependency" + index}
                dependency_key={this.props.dependency_key + "-dependency" + index}
                parent={this.props.parent + classify(this.props.element.name) + "__"}
                class={dependency_class}
                element={dependency}
                index={index}
                level={this.props.level + 1}
                path={this.props.path + "-" + index}
                handleElementChange={(e, p) => this.props.handleElementChange(e, p)}
              />
            } else {
              return "";
            }
          })
        }
      </div >
    );
  }
}

export default Element;